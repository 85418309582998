
let scrollButton = document.querySelector('#scrollToTop');

scrollButton.addEventListener('click', function () {

    window.scrollTo({ top: 0, behavior: 'smooth' });

});



let studioLink = document.querySelector('.navbar-link-lo-studio');
let avvocatiLink = document.querySelector('.navbar-link-gli-avvocati');
let contattiLink = document.querySelector('.navbar-link-contatti');


let studioTop = document.querySelector('#lo_studio').offsetTop;
let avvocatiTop = document.querySelector('#gli_avvocati').offsetTop;
let contattiTop = document.querySelector('#contatti').offsetTop;



studioLink.addEventListener('click', function () {


    window.scrollTo({ top: studioTop, behavior: 'smooth' });

});

avvocatiLink.addEventListener('click', function () {


    window.scrollTo({ top: avvocatiTop, behavior: 'smooth' });

});

contattiLink.addEventListener('click', function () {


    window.scrollTo({ top: contattiTop, behavior: 'smooth' });

});

