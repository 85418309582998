let slides = document.querySelectorAll('.hero-carousel-image');
let leftArrow = document.querySelector('#arrow-left');
let rightArrow = document.querySelector('#arrow-right')
let current = 0;


function reset(){

    for(let i = 0; i < slides.length; i++){

        slides[i].style.opacity = '0';

    }

}

function startSlides(){
    reset();

    slides[0].style.opacity = '1';

}



function slidePrev(){

    reset();

    slides[current - 1].style.opacity = "1";

    current--;

}


leftArrow.addEventListener('click', function(){

    if(current === 0){
        current = slides.length;
    }

    slidePrev();

})


function slideNext(){

    reset();

    slides[current + 1].style.opacity = "1";

    current++;

}

rightArrow.addEventListener('click', function(){

    if(current === slides.length - 1){
        current = -1;
    }

    slideNext();

})


function slider(){


    setInterval(function(){


        if(current === slides.length - 1){
            current = -1;
        }

        slideNext();


    },5000);



}


slider();
startSlides();
