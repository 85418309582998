export default () => ({
    open:'',

    init(){
        if(window.innerWidth > 0){
            if(window.innerWidth < 768){
                this.open = false;
            }else{
                this.open = true
            }
        }
    },

    trigger: {
        ['@click'](){

            return this.open = ! this.open;

        }
    },

    show: {
        ['x-show'](){

            return this.open;

        }
    },

    scroll: {
        ['@click'](){

            if(window.innerWidth < 768){
            console.log('scrolled');
            return this.open = ! this.open;
            }


        }
    }
})
